/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:31
 * @LastEditTime: 2022-04-16 17:16:02
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\theme.tsx
 */

/** 主题设置 **/
// eslint-disable-next-line import/no-anonymous-default-export
export default {
  /** 字体设置 **/
  //字体大小
  normalText: "1.4rem",
  mediumText: "1.6rem",
  largeText: "1.8rem",
  xlargeText: "2rem",
  xxlargeText: "2.4rem",
  smallText: "1.2rem",
  xsmallText: "1rem",
  xxsmallText: "0.8rem",
  //字体颜色
  fontColor: "#171717",
  successColor: "#33dd99", //草薙寧々
  dangerColor: "#ff6699", //小豆沢こはね
  warningColor: "#ffdd44", //天馬咲希
  /** 主题色设置 **/

  primaryColor: "#8888cc",
  secondaryColor: "#9595d2",
  tertiaryColor: "#b4b4e0",
  lightColor: "#d8d8f0",
  lighterColor: "#eaeaf7",
  lighterAltColor: "#fafafd",
  darkColor: "#68689b",
  darkerColor: "#4d4d72",
  darkAltColor: "#7b7bb8",
  /*  
  primaryColor: "#66327c",
  secondaryColor: "#76428d",
  tertiaryColor: "#9e71b1",
  lightColor: "#9e71b1",
  lighterColor: "#ccb1l8",
  lighterAltColor: "#f8f4fa",
  darkColor: "#4e265f",
  darkerColor: "#391c46",
  darkAltColor: "#5c2d70",
  */
 
  neutralsBlack: "#000000",
  neutralsGray190: "#201f1e",
  neutralsGray160: "#323130",
  neutralsGray150: "#3b3a39",
  neutralsGray130: "#605e5c",
  neutralsGray120: "#797775",
  neutralsGray90: "#a19f9d",
  neutralsGray60: "#c8c6c4",
  neutralsGray50: "#d2d0ce",
  neutralsGray40: "#e1dfdd",
  neutralsGray30: "#edebe9",
  neutralsGray20: "#f3f2f1",
  neutralsGray10: "#faf9f8",
  white: "#ffffff",
};
