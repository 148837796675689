/* eslint-disable @typescript-eslint/no-unused-vars */
/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:13
 * @LastEditTime: 2022-02-11 18:17:38
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Text\index.tsx
 */

import * as React from "react";
import StyledText, {
  Code,
  Deleted,
  Disabled,
  Mark,
  Strong,
  Italic,
  Underline,
} from "./style";

interface IText {
  //设置文本基础颜色样式
  type?: "primary" | "secondary" | "success" | "warning" | "danger";
  //设置文本内容
  children: React.ReactNode;
  //设置文本字体大小
  size?: "normal" | "small" | "medium" | "large" | "xs" | "xxs" | "xl" | "xxl";
  //设置所需样式
  code?: boolean;
  deleted?: boolean;
  disabled?: boolean;
  mark?: boolean;
  strong?: boolean;
  italic?: boolean;
  underline?: boolean;
}

function Text({
  type = "primary",
  children,
  size = "normal",
  code,
  deleted,
  disabled,
  mark,
  strong,
  italic,
  underline,
}: IText) {
  /**
   * @description: 根据props渲染出指定标签
   * @param {React} content
   * @return {*}
   */
  const wrapperDecorations = (content: React.ReactNode) => {
    let currentContent = content;

    const wrap = (needed: boolean | undefined, tag: React.ReactNode) => {
      if (!needed) {
        return;
      }
      currentContent = tag;
    };
    wrap(code, <Code>{currentContent}</Code>);
    wrap(deleted, <Deleted>{currentContent}</Deleted>);
    wrap(disabled, <Disabled>{currentContent}</Disabled>);
    wrap(mark, <Mark>{currentContent}</Mark>);
    wrap(strong, <Strong>{currentContent}</Strong>);
    wrap(italic, <Italic>{currentContent}</Italic>);
    wrap(underline, <Underline>{currentContent}</Underline>);
    return currentContent;
  };

  const textNode = wrapperDecorations(children);

  return (
    <StyledText size={size} type={type}>
      {textNode}
    </StyledText>
  );
}

export default Text;
