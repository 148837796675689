/*
 * @Author: litatno
 * @Date: 2021-09-09 22:07:24
 * @LastEditTime: 2022-04-18 15:14:16
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Paragraph\style.tsx
 */
import styled from "styled-components";
import { IParagraph } from ".";
import { fontSize } from "../../utils/mixins";

const StyledParagraph = styled.p<IParagraph>`
  ${({ size }) => fontSize({ size })};
  overflow: hidden;
  line-height: ${({lineHeight})=>`${lineHeight}px`};
  height: ${({ellipsis,lineHeight})=>ellipsis&&lineHeight?`${ellipsis.rows*lineHeight}px`:"auto"};
  text-overflow: ${({ellipsis})=>ellipsis?"ellipsis":"auto"};;
`;

export default StyledParagraph;
