/*
 * @Author: litatno
 * @Date: 2021-08-24 16:40:08
 * @LastEditTime: 2022-03-27 17:58:04
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\api\ApiURL.ts
 */
import ApiIp from "./ApiIp";

export const Article = (postId?: string) => {
  return postId ? `${ApiIp}/Articles/${postId}` : `${ApiIp}/Articles`;
};
export const User = (userId?: string) => {
    return userId ? `${ApiIp}/Users/${userId}` : `${ApiIp}/Users`;
};