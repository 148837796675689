/*
 * @Author: litatno
 * @Date: 2021-11-24 16:11:57
 * @LastEditTime: 2022-04-17 21:56:20
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\pages\ArticlePage\index.tsx
 */

import React from "react";
import ArticleLayout from "./style";
import ArticleItem from "../../containers/ArticleItem";
import { GetArticle } from "../../api/Api";
import { useParams } from "react-router-dom";
import ArticleHeader from "../../containers/ArticleHeader";
import ArticleContent from "../../containers/ArticleContent";

function ArticlePage() {
    const [data, setData] = React.useState([
        {
            Id: "",
            PostId: "",
            PostTime: "",
            AuthorId: "",
            ArticleTitle: "",
            ArticleContent: "",
            Readers: "",
            Commont: "",
            Good: "",
            Words: "",
            Tag: "",
            TopStatus: "",
            CommontStatus: "",  
        },
    ]);

    let params = useParams();
    let postId = params.PostId;

    React.useEffect(() => {
        GetArticle({ method: "Get" }, postId).then((response) => {
            setData(response.data);
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div className="Article">
            <ArticleLayout>
                <main>
                    <ArticleHeader data={data}></ArticleHeader>
                    <ArticleContent data={data}></ArticleContent>
                </main>
            </ArticleLayout>
        </div>
    );
}

export default ArticlePage;
