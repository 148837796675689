/*
 * @Author: litatno
 * @Date: 2021-08-18 05:25:27
 * @LastEditTime: 2022-05-09 00:13:11
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\Footer\index.tsx
 */
import * as React from "react";
import StyledFooter from "./style";

interface IFooter {}

const Footer: React.FC<IFooter> = () => {
  const startTimes = {
    year: 2020,
    month: 10,
    day: 23,
  };
  const startTime = new Date(
    startTimes.year,
    startTimes.month - 1,
    startTimes.day
  );
  const ChangeTime: any = React.useRef();
  const [time, setTime] = React.useState(new Date());
  React.useEffect(() => {
    ChangeTime.current = setInterval(() => {
      setTime(new Date());
    }, 1000);
    return ()=>{
      clearInterval(ChangeTime.current);
    }
  }, []);

  let pastTimes: number = time.getTime() - startTime.getTime();
  const PastTime = () => {
    let past = new Date(pastTimes);
    return past;
  };

  return (
    <StyledFooter>
      <p>
        本站已运行{PastTime().getUTCFullYear() - 1970}年{PastTime().getUTCMonth()}月
        {PastTime().getDate()}天{PastTime().getUTCHours()}时
        {PastTime().getUTCMinutes()}分{PastTime().getUTCSeconds()}秒
      </p>
      <p>Copyright © 2019-2021 litatno All right reserved.</p>
      <p>Made with heart by litatno</p>
      <a href="https://beian.miit.gov.cn/" target="_blank" rel="noopener noreferrer">蜀ICP备19021377号</a>
    </StyledFooter>
  );
};

export default Footer;
