/*
 * @Author: litatno
 * @Date: 2022-02-22 15:42:10
 * @LastEditTime: 2022-03-07 10:25:14
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \THblog\src\pages\NotFoundPage\index.tsx
 */
import React from "react";
import Title from "../../components/Title";
import StyledNotFoundPage from "./style";

interface INotFoundPage {
}

function NotFoundPage({}: INotFoundPage) {
  return (
    <StyledNotFoundPage>
      <Title level={1}>404 Page Not Found</Title>
    </StyledNotFoundPage>
  );
}

export default NotFoundPage;