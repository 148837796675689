/*
 * @Author: litatno
 * @Date: 2021-09-09 22:07:24
 * @LastEditTime: 2022-04-18 15:12:09
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Paragraph\index.tsx
 */
import { ReactNode } from "react";
import StyledParagraph from "./style";

interface IEllipsis{
  rows: number;
}

interface IParagraph {
  //设置文本字体大小
  size?: "normal" | "small" | "medium" | "large" | "xs" | "xxs" | "xl" | "xxl";
  lineHeight?: number;
  children?:ReactNode;
  ellipsis?: IEllipsis
}

function Paragraph({ size = "normal",lineHeight=16,children,ellipsis }: IParagraph) {


  return <StyledParagraph size={size} lineHeight={lineHeight} ellipsis={ellipsis} >{children}</StyledParagraph>;
}

export default Paragraph;
export type {IEllipsis,IParagraph};
