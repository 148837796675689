/*
 * @Author: litatno
 * @Date: 2021-10-28 13:30:37
 * @LastEditTime: 2022-05-09 00:13:25
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\FriendsLinkBox\index.tsx
 */
import React from "react";
import Title from "../../components/Title";
import StyledFriendsLinkBox, { FriendLink, Link } from "./style";

interface IFriendsLinkBox {}

function FriendsLinkBox({}: IFriendsLinkBox) {
  return (
    <StyledFriendsLinkBox>
      <FriendLink>
        <Title level={4}>友情链接：</Title>
        <Link href="https://akiravoid.com" target="_blank">
          <em> AkiraVoid </em>
        </Link>
      </FriendLink>
    </StyledFriendsLinkBox>
  );
}

export default FriendsLinkBox;
