/*
 * @Author: litatno
 * @Date: 2021-10-12 17:29:25
 * @LastEditTime: 2022-03-07 10:08:31
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \THblog\src\containers\ArticleItems\style.tsx
 */
import styled from "styled-components";

const StyledArticleItems = styled.div`
  width: 100%;
  padding: 0 24px;
  flex-shrink: 1;
  grid-area: b;
`;

export default StyledArticleItems;
