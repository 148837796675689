/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:13
 * @LastEditTime: 2022-04-21 16:30:50
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\utils\mixins.tsx
 */

import { css } from "styled-components";

// 圆形样式
interface ICircle {
  color?: string;
  size: string;
}
export const circle = ({ size, color }: ICircle) => css`
  width: ${size};
  height: ${size};
  border-radius: 50%;
  background-color: ${color};
`;


export interface IFontSize {
  size?: "normal" | "small" | "medium" | "large" | "xs" | "xxs" | "xl" | "xxl";
}
export const fontSize = ({ size }: IFontSize) => {
  const sizeVariants = {
    normal: css`
      font-size: ${({ theme }) => theme.normalText};
    `,
    medium: css`
      font-size: ${({ theme }) => theme.mediumText};
    `,
    large: css`
      font-size: ${({ theme }) => theme.largeText};
    `,
    xl: css`
      font-size: ${({ theme }) => theme.xlargeText};
    `,
    xxl: css`
      font-size: ${({ theme }) => theme.xxlargeText};
    `,
    small: css`
      font-size: ${({ theme }) => theme.smallText};
    `,
    xs: css`
      font-size: ${({ theme }) => theme.xsmallText};
    `,
    xxs: css`
      font-size: ${({ theme }) => theme.xxsmallText};
    `,
  };
  if (size === undefined) {
    size = "normal";
  }
  return sizeVariants[size];
};
