/*
 * @Author: litatno
 * @Date: 2021-08-18 05:25:20
 * @LastEditTime: 2022-03-07 10:10:34
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\Header\style.tsx
 */
import styled from "styled-components";

const StyledHeader = styled.header`
  width: 100%;
  height: 100px;
  padding: 0 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderNav = styled.div`
  width: 100%;
  max-width: 1350px;
  height: 72px;
  display: flex;

  h2 {
    line-height: 72px;
  }

  div {
    flex-grow: 1;
  }
`;

export default StyledHeader;
export { HeaderNav };
