/*
 * @Author: litatno
 * @Date: 2021-12-08 18:08:00
 * @LastEditTime: 2021-12-08 19:47:17
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\pages\BlogApp\index.tsx
 */
import { Outlet } from "react-router-dom";
import Footer from "../../containers/Footer";
import Header from "../../containers/Header";

function BlogApp() {
  return (
    <div className="BlogApp">
      <Header></Header>
      <Outlet></Outlet>
      <Footer></Footer>
    </div>
  );
}

export default BlogApp;
