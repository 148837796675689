/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:13
 * @LastEditTime: 2022-05-09 15:00:31
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Menu\style.tsx
 */

import styled, { css } from "styled-components";

interface MenuProps {
  mode: "vertical" | "horizontal" | "inline";
}

interface DividerProps {
  dashed?: boolean;
}

interface IItemStyles {
  active?:boolean;
}

const menuModeVariants = {
  vertical: css`
    flex-direction: column;
    & li {
      width: 100%;
      height: 40px;
      line-height: 40px;
    }
  `,
  horizontal: css`
    flex-direction: row;
    & li {
      height: 46px;
      line-height: 46px;
    }
  `,
  inline: css`
    flex-direction: column;
    & li {
      width: 100%;
      min-height: 40px;
      line-height: 40px;
    }
  `,
};

const StyledMenu = styled.ul<MenuProps>`
  margin: 0;
  padding: 0;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  ${({ mode }) => menuModeVariants[mode]};
`;

const StyledItem = styled.li<IItemStyles>`
  position: relative;
  margin: 0;
  padding: 0 24px;
  cursor: pointer;
  &::after {
      content: "";
      display: block;
      position: relative;
      top: 0;
      left: 0;
      border-bottom:2px solid #8866bb;
      transition: right 0.3s;
      will-change: right;
    }
  ${({active})=>active?"":css`&::after{visibility: hidden;}`}
  &:hover{
    &::after{visibility:visible;}
  }
`;

const StyledSubMenu = styled.li<IItemStyles>`
  position: relative;
  margin: 0;
  padding: 0 24px;
  &::before {
      content: "";
      display: block;
      position: relative;
      top: 0;
      left: 0;
      border-bottom:2px solid #8866bb;
      transition: right 0.3s;
      will-change: right;
    }
  ${({active})=>active?"":css`&::before{visibility: hidden;}`}
`;

const StyledItemGroup = styled.li`
  background-color: ${({ theme }) => theme.neutralsGray20};
`;

const StyledDivider = styled.div<DividerProps>`
  width: 100%;
  height: 1px;
  border-bottom: 1px ${({ dashed }) => (dashed ? "dashed" : "solid")} ${({ theme }) => theme.neutralsGray90};
`;

export default StyledMenu;
export { StyledItem, StyledSubMenu, StyledItemGroup, StyledDivider };
