/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:13
 * @LastEditTime: 2021-09-14 10:16:58
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Icon\index.tsx
 */

import StyledIcon from "./style";
interface IIcon {
  icon?: React.ComponentType<any>;
  width?: number;
  height?: number;
  color?: string;
  opacity?: string;
}
function Icon({
  icon: IconComponent,
  width = 24,
  height = 24,
  color,
  opacity,
}: IIcon) {
  return (
    <StyledIcon color={color} opacity={opacity}>
      {IconComponent && <IconComponent width={width} height={height} />}
    </StyledIcon>
  );
}

export default Icon;
