/*
 * @Author: litatno
 * @Date: 2021-09-15 11:53:51
 * @LastEditTime: 2022-05-10 10:08:57
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\Navbar\index.tsx
 */
import * as React from "react";
import Menu from "../../components/Menu";
import Text from '../../components/Text';
import StyledNavbar from "./style";

interface INavbar {}

function Navbar({}: INavbar) {
  const [selectKeys,setSelectKeys] = React.useState([]);
  return (
    <StyledNavbar>
      <Menu mode={"horizontal"} defaultSelectedKeys={["article"]} >
        <Menu.Item key={"article"} >
          <Text size={'medium'}>文 章</Text>
        </Menu.Item>
        <Menu.Item key={"document"}>
          <Text size={'medium'}>文 档</Text>
        </Menu.Item>
        <Menu.Item key={"about"}>
          <Text size={'medium'}>关 于</Text>
        </Menu.Item>
      </Menu>
    </StyledNavbar>
  );
}

export default Navbar;
