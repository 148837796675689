/*
 * @Author: litatno
 * @Date: 2022-02-12 18:14:19
 * @LastEditTime: 2022-02-22 15:29:20
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \THblog\src\pages\AdminPage\style.tsx
 */
import styled from "styled-components";


const AdminLayout = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    background-color: ${({theme})=>theme.neutralsGray20};
`;

const Side = styled.div`
    width: 280px;
    background-color: #ffffff;
`;

const Center = styled.div`
    height: 100vh;
`;


export default AdminLayout;
export {Side,Center};