/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:13
 * @LastEditTime: 2021-08-12 18:18:59
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \blog\src\components\Avatar\style.tsx
 */

import styled, { css } from "styled-components";
import { circle } from "../../utils/mixins";


// CSS设置

interface AvatarClipProps {
  size: string;
  shape: "circle" | "square";
}

interface AvatarFrameProps {
  size: string;
}

interface AvatarIconProps {
  size: string;
  identity?: "root" | "administrator" | "vip";
}

const shapeVariants = {
  circle: css`
    ${(props: AvatarClipProps) => circle({ size: props.size })};
  `,
  square: css`
    width: ${(props: AvatarClipProps) => props.size};
    height: ${(props: AvatarClipProps) => props.size};
  `,
};

const StyledAvatar = styled.div`
  position: relative;
`;

const AvatarClip = styled.div<AvatarClipProps>`
  ${({ shape }) => shapeVariants[shape]}
  overflow: hidden;
`;

const AvatarImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const AvatarFrame = styled.div<AvatarFrameProps>`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  position: absolute;
  bottom: 0;
  left: 0;
  transform: scale(1.5);
`;

const AvatarFrameImg = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
  object-position: center;
`;

const AvatarIcon = styled.div<AvatarIconProps>`
  position: absolute;
  bottom: 2px;
  right: 2px;
  z-index: 1;
  display: block;
  ${({ size }) => circle({ color: "#ffffff", size: size })};

  svg,
  svg * {
    ${({ size }) => circle({ size: size })};
    transform: scale(0.8);
  }
`;

export default StyledAvatar;
export { AvatarClip, AvatarImg, AvatarFrame, AvatarFrameImg, AvatarIcon };
