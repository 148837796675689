/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:13
 * @LastEditTime: 2021-08-12 12:49:50
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \blog\src\components\Icon\style.tsx
 */

import styled from "styled-components";

interface IconProps {
  color?: string;
  opacity?: string;
}

const StyledIcon = styled.i<IconProps>`
  display: inline-flex;
  align-items: center;
  justify-content: center;

  svg,
  svg * {
    ${({ color }) => (color ? `fill: ${color}` : "")};
    ${({ opacity }) => (opacity ? `opacity: ${opacity}` : "")};
  }
`;

export default StyledIcon;

