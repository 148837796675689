/*
 * @Author: litatno
 * @Date: 2021-09-11 00:17:47
 * @LastEditTime: 2021-10-12 17:06:09
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Input\style.tsx
 */
import styled, {css} from "styled-components";

interface InputProps {
  size: string;
  background?: string;
}

const StyledInput = styled.input`
  width: 100%;
  height: 100%;
  border: none;
  background: none;
  color: ${({ theme }) => theme.neutralsGray120};
  font-size: ${({ theme }) => theme.mediumText};
  display: block;
  &::placeholder {
    color: ${({ theme }) => theme.neutralsGray60};
  }
`;

const Prefix = styled.div`
  margin-right: 16px;
`;

const Suffix = styled.div`
  margin-left: 16px;
`;

const InputContainer = styled.div<InputProps>`
  height: ${({ size }) => size};
  display: flex;
  align-items: center;
  ${({background})=>background?css`background:${background}`:css`background: ${({ theme }) => theme.neutralsGray20};`};
  padding: 0 24px;
`;

export default StyledInput;
export { Prefix, Suffix, InputContainer };
