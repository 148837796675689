/*
 * @Author: litatno
 * @Date: 2021-08-18 03:16:43
 * @LastEditTime: 2022-03-07 10:07:38
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\ArticleHeader\style.tsx
 */
import styled from "styled-components";

const StyledArticleHeader = styled.div`
  width: 100%;
  position: relative;

  h2{
    width: 100%;
    text-align: center;
  }
`;

const ArticleInfo = styled.div`
  width: 100%;
  max-width: 400px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

 
`;

const Info = styled.div`
  position: relative;
  margin-top: 12px;
  margin-right: 12px;
  margin-left: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export default StyledArticleHeader;
export { ArticleInfo, Info };
