/*
 * @Author: your name
 * @Date: 2021-08-11 13:59:03
 * @LastEditTime: 2022-02-22 16:27:34
 * @LastEditors: Please set LastEditors
 * @Description: In User Settings Edit
 * @FilePath: \THblog\src\App.tsx
 */
import { Routes, Route } from "react-router-dom";
import ArticlePage from "./pages/ArticlePage";
import BlogApp from "./pages/BlogApp";
import HomePage from "./pages/HomePage";
import LoginPage from "./pages/LoginPage";
import AdminPage from "./pages/AdminPage";
import NotFoundPage from "./pages/NotFoundPage";

function App() {
  return (
    <div className="App">
      <Routes>
        <Route path="/" element={<BlogApp />}>
          <Route path="/" element={<HomePage />}></Route>
          <Route path="article" element={<ArticlePage />}>
            <Route path=":PostId" element={<ArticlePage />}></Route>
          </Route>
        </Route>
        <Route path="login" element={<LoginPage />}></Route>
        <Route path="admin" element={<AdminPage />}></Route>
        <Route path="*" element={<NotFoundPage />} />
      </Routes>
    </div>
  );
}

export default App;
