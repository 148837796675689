/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:13
 * @LastEditTime: 2021-08-18 14:16:53
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \blog\src\components\Avatar\index.tsx
 */
import * as React from "react";
import StyledAvatar, {
  AvatarClip,
  AvatarFrame,
  AvatarFrameImg,
  AvatarIcon,
  AvatarImg,
} from "./style";
import Icon from "../Icon";
import { ReactComponent as root } from "../../assets/icons/shield-star-fill.svg";
import { ReactComponent as administrator } from "../../assets/icons/admin-line.svg";
import { ReactComponent as vip } from "../../assets/icons/vip-crown-fill.svg";
import { ReactComponent as star } from "../../assets/icons/star-s-fill.svg";
import theme from "../../theme";

interface IAvatar {
  // 设置头像引用资源
  src: string;
  // 设置头像大小
  size?: string;
  // 设置头像形状
  shape?: "circle" | "square";
  // 设置头像框引用资源
  frameSrc?: string;
  // 设置图标身份
  identity?: "root" | "administrator" | "vip";
  // 设置图标大小
  iconSize?: string;
}
function Avatar({
  src,
  size = "80px",
  shape = "circle",
  frameSrc,
  identity,
  iconSize = "20px",
  ...restProps
}: IAvatar) {
  /**
   * @description: 图标身份判断
   * @param {string} identity
   * @return {*}
   */
  const icon = (identity: string) => {
    switch (identity) {
      case "root":
        return root;
      case "administrator":
        return administrator;
      case "vip":
        return vip;
      default:
        return star;
    }
  };

  return (
    <StyledAvatar>
      <AvatarClip size={size} shape={shape}>
        <AvatarImg src={src} alt="头像"></AvatarImg>
      </AvatarClip>
      {frameSrc && (
        <AvatarFrame size={size}>
          <AvatarFrameImg src={frameSrc}></AvatarFrameImg>
        </AvatarFrame>
      )}
      {identity && (
        <AvatarIcon identity={identity} size={iconSize}>
          <Icon
            icon={icon(identity)}
            width={16}
            height={16}
            color={theme.primaryColor}
          ></Icon>
        </AvatarIcon>
      )}
    </StyledAvatar>
  );
}

export default Avatar;
