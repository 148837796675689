/*
 * @Author: litatno
 * @Date: 2022-01-11 05:24:02
 * @LastEditTime: 2022-05-08 21:33:38
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \THblog\src\pages\ArticlePage\style.tsx
 */

import styled from "styled-components";


const ArticleLayout = styled.div`
    width: 100%;
    max-width: 1800px;
    position: relative;
    left: 50%;
    transform: translate(-50%, 0);
    display: grid;
    grid-template-columns: 1fr 360px;
    grid-template-areas: "a b";
    justify-items: center;
    padding: 24px 64px 0 64px;
    @media (max-width: 860px){
        grid-template-columns: 1fr;
    }
    @media (min-width: 860px) and (max-width: 1350px) {
        grid-template-columns: 1fr;
    }
`;

export default ArticleLayout;