/*
 * @Author: litatno
 * @Date: 2021-10-28 13:30:37
 * @LastEditTime: 2021-10-28 14:00:23
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\FriendsLinkBox\style.tsx
 */
import styled from "styled-components";

const StyledFriendsLinkBox = styled.div`
  width: 220px;
  margin: 50px 24px;
  flex-shrink: 0;
  grid-area: c;
`;

const FriendLink = styled.div`
  width: 100%;
  padding: 0 24px;
`;

const Link = styled.a`
  font-size: 1.6rem;
  font-weight: 300;
  letter-spacing: 2px;
  color: ${({ theme }) => theme.neutralsGray150};
  display: block;
  margin-bottom: 10px;
`;

export default StyledFriendsLinkBox;
export {FriendLink,Link}
