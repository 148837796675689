/*
 * @Author: litatno
 * @Date: 2022-02-22 15:42:10
 * @LastEditTime: 2022-03-07 10:13:16
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \THblog\src\pages\NotFoundPage\style.tsx
 */
import styled from "styled-components";

const  StyledNotFoundPage = styled.div`
    padding: 60px;
    text-align: center;
`;

export default StyledNotFoundPage;
