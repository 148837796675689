/*
 * @Author: litatno
 * @Date: 2021-08-18 05:25:20
 * @LastEditTime: 2022-04-18 13:49:31
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\Header\index.tsx
 */
import * as React from "react";
import StyledHeader, { HeaderNav } from "./style";
import Title from "../../components/Title";
import Input from "../../components/Input";
import Navbar from "../Navbar";

interface IHeader {}

const Header: React.FC<IHeader> = () => {
  return (
    <StyledHeader>
      <HeaderNav>
        <a href="/">
          <Title level={2}>THlane</Title>
        </a>
        <Input size={"72px"} background={"#ffffff"}></Input>
        <Navbar></Navbar>
      </HeaderNav>
    </StyledHeader>
  );
};

export default Header;
