/*
 * @Author: litatno
 * @Date: 2021-09-09 22:05:27
 * @LastEditTime: 2022-04-21 16:17:33
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\ArticleContent\index.tsx
 */
import Paragraph, { IEllipsis } from "../../components/Paragraph";
import StyledArticleContent from "./style";

interface IArticleContent {
    data: any;
    preview?: boolean;
    ellipsis?: IEllipsis
}

function ArticleContent({ data, preview,ellipsis }: IArticleContent) {
    const htmltext = () => {
        let context = { __html: data.ArticleContent };
        return context;
    };
    const previewText = () => {
        let htmlRegex:RegExp = /<.*?>/g;
        if (data.ArticleContent) {
          let context = data.ArticleContent.replace(htmlRegex, "");
          return context;
        }else{
          return null;
        }
    };

    const HTMLRender = ()=>{
        let context = data.ArticleContent;

    }

    return (
        <StyledArticleContent>
            {preview ? (
                <Paragraph size="medium" lineHeight={24} ellipsis={ellipsis}>{previewText()}</Paragraph>
            ) : (
                <div dangerouslySetInnerHTML={htmltext()}></div>
            )}
        </StyledArticleContent>
    );
}

export default ArticleContent;
