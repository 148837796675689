/*
 * @Author: litatno
 * @Date: 2021-08-15 19:31:05
 * @LastEditTime: 2022-04-18 15:29:18
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\ArticleItem\index.tsx
 */
import StyledArticleItem from "./style";
import ArticleHeader from "../ArticleHeader";
import ArticleContent from "../ArticleContent";
import Button from "../../components/Button";
import { Link } from "react-router-dom";

interface IArticleItem {
    data: any;
}

function ArticleItem({ data }: IArticleItem) {
    const ellipsis = {
        rows: 4,
    };

    return (
        <StyledArticleItem>
            <ArticleHeader data={data}></ArticleHeader>
            <ArticleContent preview ellipsis={ellipsis} data={data}></ArticleContent>
            <Link to={`/article/${data.PostId}`}>
                <Button type={"text"} size={"20px"}>
                    {"--> 阅读全文"}
                </Button>
            </Link>
        </StyledArticleItem>
    );
}

export default ArticleItem;
