/*
 * @Author: litatno
 * @Date: 2021-08-15 19:31:05
 * @LastEditTime: 2022-04-17 21:08:11
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\ArticleItem\style.tsx
 */
import styled from "styled-components";

const StyledArticleItem = styled.article`
    width: 100%;
    padding: 24px;
    margin-bottom: 60px;
    position: relative;

    button {
        position: absolute;
        bottom: -20px;
        right: 24px;
        background-color: rgba(255, 255, 255, 0);
    }
`;

export default StyledArticleItem;
