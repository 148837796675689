/*
 * @Author: litatno
 * @Date: 2021-12-08 17:30:15
 * @LastEditTime: 2022-03-06 12:07:57
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\pages\LoginPage\style.tsx
 */

import styled from "styled-components";

const LonginPageLayout = styled.div`
  width: 100%;
  height: 100%;
`;

const LoginBox = styled.div`
  width: 440px;
  height: 350px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  box-shadow: 0 2px 6px rgb(0 0 0 / 20%);
  display: grid;
  grid-template-columns: 24px 1fr 24px;
  grid-template-rows: 100px 48px 24px 48px 1fr;
  grid-template-areas:". . ." ". a ." ". . ." ". b ." ". c .";

  & div:nth-of-type(1) {
      grid-area: a;
  }
  & div:nth-of-type(2) {
      grid-area: b;
  }
  & button{
      width: 128px;
      grid-area: c;
      position: relative;
      left: 50%;
      top: 30%;
      transform: translate(-50%,0);
  }
`;

export default LonginPageLayout;
export { LoginBox };
