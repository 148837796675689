/*
 * @Author: litatno
 * @Date: 2021-08-18 03:25:50
 * @LastEditTime: 2021-08-21 14:49:11
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \blog\src\components\Title\index.tsx
 */
import * as React from "react";
import StyledTitle from "./style";

interface ITitle {
  children: React.ReactNode;
  level: 1 | 2 | 3 | 4 | 5;
  onClick?: () => void;
}

function Title({ level = 1, children }: ITitle) {
  return <StyledTitle as={`h${level}`}>{children}</StyledTitle>;
}

export default Title;
