/*
 * @Author: litatno
 * @Date: 2021-08-24 16:38:09
 * @LastEditTime: 2022-03-27 18:01:44
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\api\Api.ts
 */
import axios from "axios";
import * as ApiURL from "./ApiURL";

interface configObject {
  [key: string]: any;
}

const GetData = (apiURL: string, configObj: configObject) => {
  let { method = "GET", params = {}, data = {}, timeout = 5000 } = configObj;
  return new Promise((resolve:(value:any)=>void, reject) => {
    axios({
      url: apiURL,
      method: method,
      params: params,
      data: data,
      timeout: timeout,
      headers: {
        "Content-Type": "application/json",
        token: window.sessionStorage.getItem("token") || "",
      },
    })
      .then((response) => {
        if (response) {
          if (response.data) {
            resolve(response);
          } else {
            resolve(response);
          }
        } else {
          resolve(response);
        }
      })
      .catch((error) => {
        reject(error);
      });
  });
};

export const GetArticle = (configObj: object,postId?:string) => {
  return GetData(ApiURL.Article(postId), configObj);
};
export const GetUser = (configObj: object,userId?:string) =>{
  return GetData(ApiURL.User(userId),configObj);
}