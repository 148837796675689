/*
 * @Author: litatno
 * @Date: 2021-08-24 16:46:07
 * @LastEditTime: 2022-05-08 11:24:59
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \THblog\src\api\ApiIp.ts
 */
const Ip = "https://api.thlane.com/api";

export default Ip;