/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:13
 * @LastEditTime: 2022-05-10 11:25:17
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Menu\index.tsx
 */

import React from "react";
import Icon from "../Icon";
import StyledMenu, {
  StyledItem,
  StyledSubMenu,
  StyledItemGroup,
  StyledDivider,
} from "./style";

interface IMenuBase {
  mode?: "vertical" | "horizontal" | "inline";
  active?: boolean;
}

interface IMenu {
  mode?: "vertical" | "horizontal" | "inline";
  defaultOpenKeys?: string[];
  defaultSelectedKeys?: string[];
  selectedKeys?: string[];
  children: React.ReactNode;
}

interface IItem extends IMenuBase {
  key: string;
  id?: string;
  icon?: React.ComponentType<any>;
  onClick?: () => void;
  setAllSelectedKeys?: React.Dispatch<React.SetStateAction<string[]>>;
  children: React.ReactNode;
}

interface ISubMenu extends IMenuBase {
  key?: string;
  title: string;
  children: React.ReactNode;
}

interface IDivider {
  dashed?: boolean;
}

function Item({
  key,
  id,
  icon,
  active,
  onClick,
  setAllSelectedKeys,
  children,
}: IItem) {
  const OnClickHandle = () => {
    if (onClick) {
      onClick();
    }
    if (id) {
      let selectedKeys: string[] = [id];
      if (setAllSelectedKeys) {
        setAllSelectedKeys(selectedKeys);
      }
    }
  };

  return (
    <StyledItem key={key} active={active} onClick={OnClickHandle}>
      <Icon icon={icon}></Icon>
      {children}
    </StyledItem>
  );
}

function SubMenu({ title, active, children }: ISubMenu) {
  return (
    <StyledSubMenu active={active}>
      {title}
      {children}
    </StyledSubMenu>
  );
}

function ItemGroup() {
  return <StyledItemGroup></StyledItemGroup>;
}

function Divider({ dashed }: IDivider) {
  return <StyledDivider dashed={dashed}></StyledDivider>;
}

function Menu({
  mode = "vertical",
  defaultSelectedKeys,
  selectedKeys,
  children,
}: IMenu) {
  const [allSelectedKeys, setAllSelectedKeys] = React.useState<
    string[] | undefined
  >([]);
  if (
    selectedKeys === undefined ||
    selectedKeys === [] ||
    selectedKeys === null
  ) {
    selectedKeys = defaultSelectedKeys;
  }
  React.useEffect(() => {
    setAllSelectedKeys(selectedKeys);
  }, []);
  const Select = () => {
    if (React.Children.count(children) > 0) {
      return React.Children.map(children, (child) => {
        if (allSelectedKeys) {
          for (let i = 0; i < allSelectedKeys.length; i++) {
            let selectedKey = allSelectedKeys[i];
            let childProps = (child as React.ReactElement).props;
            let childKey = (child as React.ReactElement).key;
            if (childKey === selectedKey) {
              return React.cloneElement(child as React.ReactElement, {
                id: childKey,
                active: true,
                setAllSelectedKeys: setAllSelectedKeys,
                childProps,
              });
            } else {
              return React.cloneElement(child as React.ReactElement, {
                id: childKey,
                active: false,
                setAllSelectedKeys: setAllSelectedKeys,
                childProps,
              });
            }
          }
        }
      });
    } else {
      return undefined;
    }
  };
  let childNode = Select();
  return <StyledMenu mode={mode}>{childNode}</StyledMenu>;
}

Menu.Item = Item;

Menu.SubMenu = SubMenu;

Menu.ItemGroup = ItemGroup;

Menu.Divider = Divider;

export default Menu;
