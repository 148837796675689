/*
 * @Author: litatno
 * @Date: 2021-08-18 03:16:43
 * @LastEditTime: 2022-04-17 21:56:01
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\ArticleHeader\index.tsx
 */
import Icon from "../../components/Icon";
import Title from "../../components/Title";
import Text from "../../components/Text";
import StyledArticleHeader, { ArticleInfo, Info } from "./style";
import { ReactComponent as User } from "../../assets/icons/user.svg";
import { ReactComponent as Calendar } from "../../assets/icons/calendar.svg";
import { ReactComponent as Eyes } from "../../assets/icons/eyes.svg";
import { ReactComponent as Comment } from "../../assets/icons/comment.svg";
import { ReactComponent as Good } from "../../assets/icons/good.svg";
import { ReactComponent as Word } from "../../assets/icons/word.svg";
import { ReactComponent as Filter } from "../../assets/icons/filter.svg";

interface IArticleHeader {
  data: any
  type?: "simple" | "detail";
}
interface IHeaderInfo {
  icon: React.ComponentType<any>;
  data?: string;
}

function HeaderInfo({ icon, data }: IHeaderInfo) {
  return (
    <Info>
      <Icon icon={icon} width={16} height={16}></Icon>
      <Text>{data}</Text>
    </Info>
  );
}

function ArticleHeader({ data }: IArticleHeader) {
  return (
    <StyledArticleHeader>
      <Title level={2}>{data.ArticleTitle}</Title>
      <ArticleInfo>
        <HeaderInfo icon={User} data={data.AuthorId}></HeaderInfo>
        <HeaderInfo icon={Calendar} data={data.PostTime}></HeaderInfo>
        <HeaderInfo icon={Eyes} data={data.Readers}></HeaderInfo>
        <HeaderInfo icon={Comment} data={data.Commont}></HeaderInfo>
        <HeaderInfo icon={Good} data={data.Good}></HeaderInfo>
        <HeaderInfo icon={Word} data={data.Words}></HeaderInfo>
        <HeaderInfo icon={Filter} data={data.Tag}></HeaderInfo>
      </ArticleInfo>
    </StyledArticleHeader>
  );
}

export default ArticleHeader;
