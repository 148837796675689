/*
 * @Author: litatno
 * @Date: 2021-09-11 00:17:47
 * @LastEditTime: 2022-03-06 13:59:11
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Input\index.tsx
 */
import React from "react";
import StyledInput, { InputContainer, Prefix, Suffix } from "./style";

interface IInput {
  placeholder?: string;
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
  size?: string;
  type?: string;
  id?: string;
  background?:string;
  onChange?:(e:any)=>void;
  
}

function Input({
  placeholder = "请输入内容...",
  prefix,
  suffix,
  size = "48px",
  type = "text",
  onChange,
  id,
  background
}: IInput) {
  return (
    <InputContainer size={size} background={background}>
      {prefix && <Prefix>{prefix}</Prefix>}
      <StyledInput type={type} id={id} placeholder={placeholder} onChange={onChange}></StyledInput>
      {suffix && <Suffix>{suffix}</Suffix>}
    </InputContainer>
  );
}

export default Input;
