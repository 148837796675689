/*
 * @Author: litatno
 * @Date: 2021-08-15 16:49:08
 * @LastEditTime: 2021-10-28 14:00:14
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\SiteBox\style.tsx
 */
import styled, { css } from "styled-components";
import StyledAvatar from "../../components/Avatar/style";
import StyledText from "../../components/Text/style";

interface InfoProps {
  border?: boolean;
}

const Info = styled.div<InfoProps>`
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  flex-direction: column;
  justify-content: center;
  ${({ border }) =>
    border
      ? css`
          border-left: 1px solid #a19f9d;
          border-right: 1px solid #a19f9d;
        `
      : ""}
`;

const Links = styled.div`
  width: 120px;
  height: 80px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;

  span {
    margin: 4px;
  }
`;

const StyledSiteBox = styled.div`
  width: 220px;
  height: 280px;
  margin: 50px 24px;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-rows: 80px 50px 50px 1fr;
  grid-template-areas: "a a a" "b b b" "c d e" "f f f";
  place-items: center center;

  ${StyledAvatar} {
    grid-area: a;
  }

  ${StyledText} {
    grid-area: b;
  }

  ${Links} {
    grid-area: f;
  }
`;

export default StyledSiteBox;
export { Info, Links };
