/*
 * @Author: litatno
 * @Date: 2021-08-18 03:25:50
 * @LastEditTime: 2021-08-18 03:59:54
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \blog\src\components\Title\style.tsx
 */
import styled from "styled-components";

const  StyledTitle = styled.div`

`;

export default StyledTitle;
