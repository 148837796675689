/*
 * @Author: litatno
 * @Date: 2021-08-21 01:11:57
 * @LastEditTime: 2022-03-06 13:54:02
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Button\index.tsx
 */
import React from "react";
import StyledButton from "./style";

interface IButton {
  children?: React.ReactNode;
  type?: "primary" | "default" | "dashed" | "text";
  shape?: "rect" | "circle";
  size?: string;
  ghost?: boolean;
  disabled?: boolean;
  danger?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  loadType?: "default";
  htmlType?: string;
  onClick?: () => void;
}

function Button({
  children,
  type = "default",
  shape = "rect",
  size = "32px",
  ghost,
  disabled,
  danger,
  icon,
  htmlType = "button",
  onClick,
}: IButton) {

  return (
    <StyledButton
      btype={type}
      shape={shape}
      size={size}
      ghost={ghost}
      disabled={disabled}
      danger={danger}
      onClick={onClick}
    >
      {icon}
      {children}
    </StyledButton>
  );
}

export default Button;
