/*
 * @Author: litatno
 * @Date: 2021-10-12 17:11:21
 * @LastEditTime: 2022-01-11 05:26:27
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\pages\HomePage\index.tsx
 */
import ArticleItems from "../../containers/ArticleItems";
import FriendsLinkBox from "../../containers/FriendsLinkBox";
import SiteBox from "../../containers/SiteBox";
import  HomeLayout  from "./style";

function HomePage() {
  return (
    <div className="HomePage">
      <HomeLayout>
        <SiteBox></SiteBox>
        <ArticleItems></ArticleItems>
        <FriendsLinkBox></FriendsLinkBox>
      </HomeLayout>
    </div>
  );
}
export default HomePage;
