/*
 * @Author: litatno
 * @Date: 2021-08-18 05:25:27
 * @LastEditTime: 2022-03-07 10:08:43
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \THblog\src\containers\Footer\style.tsx
 */
import styled from "styled-components";

const StyledFooter = styled.footer`
  width: 100%;
  border-top: 1px solid ${({theme})=>theme.neutralsGray40};
  text-align: center;
  padding: 48px 24px;
`;

export default StyledFooter;
