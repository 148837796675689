/*
 * @Author: litatno
 * @Date: 2021-08-11 14:10:13
 * @LastEditTime: 2022-01-11 02:50:44
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\components\Text\style.tsx
 */

import styled, { css } from "styled-components";
import { fontSize } from "../../utils/mixins";

interface TextProps {
  size: "normal" | "small" | "medium" | "large" | "xs" | "xxs" | "xl" | "xxl";
  type: "primary" | "secondary" | "success" | "warning" | "danger";
}

const typeVariants = {
  primary: css`
    color: ${({ theme }) => theme.fontColor};
  `,
  secondary: css`
    color: ${({ theme }) => theme.fontColor};
    opacity: 0.5;
  `,
  success: css`
    color: ${({ theme }) => theme.successColor};
  `,
  warning: css`
    color: ${({ theme }) => theme.warningColor};
  `,
  danger: css`
    color: ${({ theme }) => theme.dangerColor};
  `,
};

const StyledText = styled.span<TextProps>`
  ${({ size }) => fontSize({size})};
  ${({ type }) => typeVariants[type]};
  font-weight: 500;
`;

const Code = styled.code`
  display: inline-block;
  padding: 0 4px;
  margin: 0 4px;
  border: 1px solid ${({ theme }) => theme.neutralsGray60};
  border-radius: 4px;
  background-color: ${({ theme }) => theme.neutralsGray20};
`;

const Deleted = styled.del``;

const Disabled = styled.span`
  cursor: not-allowed;
  user-select: none;
  opacity: 0.5;
`;

const Mark = styled.mark``;

const Strong = styled.strong``;

const Italic = styled.em``;

const Underline = styled.u``;

export default StyledText;
export { Code, Deleted, Disabled, Mark, Strong, Italic, Underline };
