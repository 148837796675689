/*
 * @Author: litatno
 * @Date: 2022-02-12 18:13:53
 * @LastEditTime: 2022-05-10 09:34:18
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\pages\AdminPage\index.tsx
 */
import Menu from "../../components/Menu";
import Text from "../../components/Text";
import AdminLayout, { Side,Center } from "./style";

function AdminPage() {
  return (
    <div className="Admin">
      <AdminLayout>
        <Side>
          <Menu mode={"inline"}>
            <Menu.Item key={"admin-all"} >
              <Text size={"medium"}>总 览</Text>
            </Menu.Item>
            <Menu.Item key={"admin-users"} >
              <Text size={"medium"}>用 户</Text>
            </Menu.Item>
            <Menu.Item key={"admin-articles"}>
              <Text size={"medium"}>文 章</Text>
            </Menu.Item>
            <Menu.Item key={"admin-documents"}>
              <Text size={"medium"}>文 档</Text>
            </Menu.Item>
            <Menu.Item key={"admin-links"}>
              <Text size={"medium"}>链 接</Text>
            </Menu.Item>
          </Menu>
        </Side>
        <Center></Center>
      </AdminLayout>
    </div>
  );
}

export default AdminPage;
