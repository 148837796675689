/*
 * @Author: litatno
 * @Date: 2021-08-21 01:11:57
 * @LastEditTime: 2021-08-22 00:42:16
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \blog\src\components\Button\style.tsx
 */
import styled, { css } from "styled-components";
import { circle } from "../../utils/mixins";

interface ButtonProps {
  btype: "primary" | "default" | "dashed" | "text";
  shape: "rect" | "circle";
  size: string;
  ghost?: boolean;
  disabled?: boolean;
  danger?: boolean;
  icon?: React.ReactNode;
  loading?: boolean;
  loadType?: "default";
  htmlType?: string;
}

const typeVariants = {
  default: css`
    border: 1px solid ${({ theme }) => theme.neutralsGray90};
    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      border-color: ${({ theme }) => theme.primaryColor};
    }
    &:active {
      background-color: ${({ theme }) => theme.lightColor};
    }
  `,
  primary: css`
    outline: none;
    border: 1px solid rgba(255, 255, 255, 0);
    color: ${({ theme }) => theme.white};
    background-color: ${({ theme }) => theme.primaryColor};
    &:hover {
      background-color: ${({ theme }) => theme.darkAltColor};
    }
    &:active {
      background-color: ${({ theme }) => theme.darkColor};
    }
  `,
  dashed: css`
    border: 1px dashed ${({ theme }) => theme.neutralsGray90};
    &:hover {
      color: ${({ theme }) => theme.primaryColor};
      border-color: ${({ theme }) => theme.primaryColor};
    }
    &:active {
      background-color: ${({ theme }) => theme.lightColor};
    }
  `,
  text: css`
    border: none;
    &:hover {
      color: ${({ theme }) => theme.primaryColor};;
    }
    &:active {
      background-color: ${({ theme }) => theme.lightColor};
    }
  `,
  ghost: css`
    color: ${({ theme }) => theme.white};
    border-color: ${({ theme }) => theme.neutralsGray90};
    background-color: rgba(255, 255, 255, 0);
    &:hover {
      border-color: ${({ theme }) => theme.white};
    }
   
  `,
  ghostP: css`
    color: ${({ theme }) => theme.primaryColor};
    background-color: rgba(255, 255, 255, 0);
    border-color: ${({ theme }) => theme.primaryColor};
    &:hover {
      color: ${({ theme }) => theme.darkAltColor};
      border-color: ${({ theme }) => theme.darkAltColor};
      background-color: rgba(255, 255, 255, 0);
    }
    &:active {
      background-color: ${({ theme }) => theme.darkColor};
    }
  `,
  danger: css`
    color: ${({ theme }) => theme.dangerColor};
    border-color: ${({ theme }) => theme.dangerColor};
    &:hover {
      color: ${({ theme }) => theme.dangerColor};
      background-color: "#ffbdc2";
      border-color: ${({ theme }) => theme.dangerColor};
    }
    &:active {
      border-color: ${({ theme }) => theme.dangerColor};
    }
  `,
  dangerP: css`
    background-color: ${({ theme }) => theme.dangerColor};
    &:hover {
      background-color: "#fa4453";
    }
    &:active {
      background-color: "#ff192d";
    }
  `,
  disabled: css`
    cursor: not-allowed;
    color: ${({ theme }) => theme.neutralsGray120};
    background-color: ${({ theme }) => theme.neutralsGray60};
    border-color: ${({ theme }) => theme.neutralsGray50};
  `,
};

const buttonType = (
  btype: string,
  ghost: boolean | undefined,
  danger: boolean | undefined,
  disabled: boolean | undefined
) => {
  if (ghost) {
    if (btype === "primary") {
      return typeVariants["ghostP"];
    }
    return typeVariants["ghost"];
  }
  if (danger) {
    if (btype === "primary") {
      return typeVariants["dangerP"];
    }
    return typeVariants["danger"];
  }
  if (disabled) {
    return typeVariants["disabled"];
  }
};

const shapeVariants = {
  rect: css`
    height: ${(props: ButtonProps) => props.size};
    padding: 0px 16px;
    border-radius: 2px;
  `,
  circle: css`
    ${(props: ButtonProps) => circle({ size: props.size })};
    display: inline-flex;
    justify-content: center;
    align-items: center;
  `,
};

const StyledButton = styled.button<ButtonProps>`
  cursor: pointer;
  box-sizing: border-box;
  ${({ shape }) => shapeVariants[shape]};
  ${({ btype }) => typeVariants[btype]};
  ${({ btype, ghost, danger, disabled }) =>
    buttonType(btype, ghost, danger, disabled)}
`;

export default StyledButton;
