/*
 * @Author: litatno
 * @Date: 2021-12-08 17:29:49
 * @LastEditTime: 2022-03-07 10:03:01
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\pages\LoginPage\index.tsx
 */
import React from "react";
import Button from "../../components/Button";
import Input from "../../components/Input";
import LonginPageLayout, { LoginBox } from "./style";

function LoginPage() {

  const [username,SetUsername] = React.useState();
  const [password,SetPassword] = React.useState();
  const Login = () => {
    console.log(username);
    console.log(password);
  }

  return (
    <div className="LoginPage">
      <LonginPageLayout>
        <LoginBox>
          <Input id={"username"} placeholder={"用户名"} onChange={(e)=>SetUsername(e.target.value)}></Input>
          <Input id={"password"} type={"password"} placeholder={"密码"} onChange={(e)=>SetPassword(e.target.value)}></Input>
          <Button type={"primary"} onClick={()=>Login()}>登 录</Button>
        </LoginBox>
      </LonginPageLayout>
    </div>
  );
}

export default LoginPage;
