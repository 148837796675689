/*
 * @Author: litatno
 * @Date: 2021-09-15 11:53:51
 * @LastEditTime: 2022-03-07 10:11:00
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\Navbar\style.tsx
 */
import styled from "styled-components";

const StyledNavbar = styled.nav`
  display: flex;
  flex-direction: row;
  justify-content: end;
`;

export default StyledNavbar;
