/*
 * @Author: litatno
 * @Date: 2021-10-13 16:07:22
 * @LastEditTime: 2022-01-11 05:25:08
 * @LastEditors: litatno
 * @Description: 
 * @FilePath: \THblog\src\pages\HomePage\style.tsx
 */
import styled from "styled-components";

const HomeLayout = styled.div`
  width: 100%;
  max-width: 1600px;
  position: relative;
  left: 50%;
  transform: translate(-50%, 0);
  display: grid;
  grid-template-columns: 268px 1fr 268px;
  grid-template-areas: "a b c";
  justify-items: center;
  padding-top: 80px;

  @media (max-width: 860px) {
    grid-template-columns: 1fr;
    grid-template-rows: 380px 1fr auto;
    grid-template-areas: "a" "b" "c";
  }

  @media (min-width: 860px) and (max-width: 1350px) {
    grid-template-columns: 268px 1fr;
    grid-template-rows: 380px auto;
    grid-template-areas: "a b" "c b";
  }
  `;
export default HomeLayout;