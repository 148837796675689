/*
 * @Author: litatno
 * @Date: 2021-10-12 17:29:25
 * @LastEditTime: 2022-04-17 21:11:10
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\ArticleItems\index.tsx
 */
import React from "react";
import StyledArticleItems from "./style";
import ArticleItem from "../ArticleItem";
import { GetArticle } from "../../api/Api";

interface IArticleItems {}

function ArticleItems({}: IArticleItems) {
  const [data, SetData] = React.useState([]);

  React.useEffect(() => {
    GetArticle({ method: "Get" }).then((response) => {
      SetData(response.data);
    });
  }, []);

  return (
    <StyledArticleItems>
      {data.map((data, index) => (
        <ArticleItem key={index} data={data}></ArticleItem>
      ))}
    </StyledArticleItems>
  );
}

export default ArticleItems;
