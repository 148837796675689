/*
 * @Author: litatno
 * @Date: 2021-09-09 22:05:27
 * @LastEditTime: 2022-04-17 21:08:23
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\ArticleContent\style.tsx
 */
import styled from "styled-components";

const StyledArticleContent = styled.div`
  padding-top: 6px;
`;

export default StyledArticleContent;
