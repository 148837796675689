/*
 * @Author: litatno
 * @Date: 2021-08-15 16:49:08
 * @LastEditTime: 2022-03-07 10:22:09
 * @LastEditors: litatno
 * @Description:
 * @FilePath: \THblog\src\containers\SiteBox\index.tsx
 */

import Avatar from "../../components/Avatar";
import Icon from "../../components/Icon";
import Text from "../../components/Text";
import StyledSiteBox, { Info, Links } from "./style";

import { ReactComponent as GithubIcon } from "../../assets/icons/github.svg";
import { ReactComponent as BilibiliIcon } from "../../assets/icons/bilibili.svg";
import { ReactComponent as TwitterIcon } from "../../assets/icons/twitter.svg";
import { ReactComponent as TelegramIcon } from "../../assets/icons/telegram.svg";
import { ReactComponent as SteamIcon } from "../../assets/icons/steam.svg";

interface ISiteBox {}

interface ISiteInfo {
  num: number;
  text: string;
  border?: boolean;
}

interface ILinks {
  children: React.ReactNode;
}

function SiteInfo({ num, text, border }: ISiteInfo) {
  return (
    <Info border={border}>
      <Text size={"large"}>{num}</Text>
      <Text>{text}</Text>
    </Info>
  );
}

function SiteLinks({ children }: ILinks) {
  return <Links>{children}</Links>;
}

function SiteBox({}: ISiteBox) {
  return (
    <StyledSiteBox>
      <Avatar src={"https://tva2.sinaimg.cn/large/007X4tqyly1gy6bucyrg5j30nm0q7q5u.jpg"}></Avatar>
      <Text size={"xl"}>litatno</Text>
      <SiteInfo num={1} text={"文章"} />
      <SiteInfo num={0} text={"文档"} border />
      <SiteInfo num={0} text={"动态"} />
      <SiteLinks>
        <span>
          <a
            href={"https://space.bilibili.com/14140515"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon={BilibiliIcon} />
          </a>
        </span>
        <span>
          <a
            href={"https://github.com/liTATn"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon={GithubIcon} />
          </a>
        </span>
        <span>
          <a
            href={"https://twitter.com/liTATno"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon={TwitterIcon} />
          </a>
        </span>
        <span>
          <a href={"./"} target="_blank" rel="noopener noreferrer">
            <Icon icon={TelegramIcon} />
          </a>
        </span>
        <span>
          <a
            href={"https://steamcommunity.com/id/litatno/"}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Icon icon={SteamIcon} />
          </a>
        </span>
      </SiteLinks>
    </StyledSiteBox>
  );
}

export default SiteBox;
